import React from "react";
import {
  styled,
  Box,
  Typography,
  useTheme,
  Container,
  Button,
  Hidden
} from "@material-ui/core";

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: React.ReactNode | string;
  secondaryTitle?: string;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  backgroundUrl?: string;
};

const WhiteButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  "&:hover": {
    background: theme.palette.common.white
  }
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  "& a": {
    color: theme.palette.primary.main,
    fontSize: "inherit"
  },
  "& strong": {
    color: theme.palette.text.primary,
    fontSize: "inherit",
    fontWeight: 600
  },
  [theme.breakpoints.down("sm")]: {
    color: theme.palette.grey[600]
  }
}));

const Wrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    height: `calc(100vh - var(--header-height) - env(safe-area-inset-top))`,
    background: `linear-gradient(90deg, #00141D 50%, ${theme.palette.grey[200]} 50%)`,
    position: "absolute",
    top: `calc(var(--header-height) + env(safe-area-inset-top))`,
    left: 0,
    width: "100%",
    zIndex: 200
  },
  [theme.breakpoints.down("xs")]: {
    paddingBottom: theme.spacing(4)
  }
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  height: "100%",

  [theme.breakpoints.down("sm")]: {
    padding: 0,
    maxWidth: "500px"
  }
}));

export default function UserAuthenticationLayout({
  children,
  title,
  description,
  secondaryTitle,
  secondaryButtonText,
  onSecondaryButtonClick,
  backgroundUrl
}: Props) {
  const theme = useTheme();

  return (
    <Wrapper>
      <CustomContainer maxWidth="lg">
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
        >
          {/* Left panel */}
          <Box
            flex={1}
            bgcolor={{ xs: "background.default", md: "transparent" }}
            paddingTop={{ xs: 4, md: 0 }}
            paddingX={{ xs: 2, md: 0 }}
            paddingBottom={{ xs: 4, md: 0 }}
            width="100%"
            maxWidth={{ xs: "none", md: theme.spacing(60) }}
            display={{ xs: "none", md: "flex" }}
            className="left-panel"
          >
            {title && (
              <Box marginBottom={4}>
                <Typography variant="h4">{title}</Typography>
              </Box>
            )}
            {description && (
              <DescriptionTypography
                // @ts-ignore
                component="div"
                dangerouslySetInnerHTML={{ __html: description as string }}
              />
            )}

            {(secondaryTitle || secondaryButtonText) && (
              <>
                <Hidden smDown implementation="css">
                  <Box
                    marginTop={description ? 7 : 0}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="body2">{secondaryTitle}</Typography>
                    <WhiteButton
                      variant="text"
                      size="small"
                      onClick={onSecondaryButtonClick}
                      style={{
                        marginLeft: theme.spacing(2)
                      }}
                    >
                      {secondaryButtonText}
                    </WhiteButton>
                  </Box>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <Box
                    marginTop={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="body2">{secondaryTitle}</Typography>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={onSecondaryButtonClick}
                    >
                      {secondaryButtonText}
                    </Button>
                  </Box>
                </Hidden>
              </>
            )}
          </Box>

          {/* Right panel */}
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            width="100%"
            paddingX={{ xs: 2, md: 0 }}
            paddingTop={{ xs: 2, sm: 6, md: 0 }}
            paddingBottom={{ xs: 2, md: 0 }}
            className="right-panel"
          >
            <Box width="100%" maxWidth={{ xs: "none", md: "430px" }}>
              {children}
            </Box>
          </Box>

          {/* Desktop background */}
          <Hidden smDown implementation="css">
            <Box
              position="absolute"
              top="0"
              left="0"
              width="50vw"
              height="100%"
              zIndex="-1"
              style={
                backgroundUrl
                  ? {
                      backgroundSize: "cover",
                      backgroundImage: `url(${backgroundUrl}?auto=format&q=50&w=2000)`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center"
                    }
                  : null
              }
            />
          </Hidden>
        </Box>
      </CustomContainer>
    </Wrapper>
  );
}
