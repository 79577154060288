import React, { useEffect } from "react";
import Authenticate from "./containers/Authenticate";
import AuthenticationLayout from "./components/Layout";
import { navigate } from "gatsby";
import useLocalizedLocationPath from "../../hooks/use-localized-location-path";
import useIsAuthenticated from "../../hooks/use-is-authenticated";
import { StringParam, useQueryParam } from "use-query-params";
import useFeatureFlag from "../../hooks/use-feature-flag";

/**
 * Global sign up/in page (/register, /sign-in)
 */
type Props = {
  type: "sign-in" | "register";
};

export default function AuthenticationPage({ type }: Props) {
  const isAuthenticated = useIsAuthenticated();
  const getLocalizedPath = useLocalizedLocationPath();
  const [redirect] = useQueryParam("redirect", StringParam);
  const isShopEnabled = useFeatureFlag("SHOP_DISCOVER");

  // If user is already authenticated when landing on this page, redirect right away
  useEffect(() => {
    if (isAuthenticated) {
      redirectAfterAuthentication();
    }
  }, []);

  const redirectAfterAuthentication = () => {
    navigate(
      redirect || getLocalizedPath(isShopEnabled ? "/shop" : "/user/account")
    );
  };

  return (
    <AuthenticationLayout
      backgroundUrl={
        type === "sign-in"
          ? "https://www.datocms-assets.com/15671/1622043730-06-airgraft2-arches2.png"
          : "https://www.datocms-assets.com/15671/1622043059-02-airgraft-2-vaporizer.jpg"
      }
    >
      <Authenticate
        type={type}
        onAuthentication={redirectAfterAuthentication}
        fullHeight
        minHeight={{
          xs: `calc(var(--100vh, 100vh) - var(--header-height) - 32px)`,
          sm: "0px"
        }}
      />
    </AuthenticationLayout>
  );
}
