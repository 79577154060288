import React from "react";
import AuthPage from "../containers/authentication";
import { graphql } from "gatsby";
import Seo from "../components/seo";

const RegisterPage = ({ data }: { data: Queries.RegisterQueryDataQuery }) => {
  return (
    <>
      <Seo
        seo={data.datoCmsWebPage?.seo}
        seoMetaTags={data.datoCmsWebPage?.seoMetaTags}
      />
      <AuthPage type="register" />
    </>
  );
};

export const query = graphql`
  query RegisterQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "register" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
  }
`;

export default RegisterPage;
